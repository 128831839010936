import styled from 'styled-components'

import { gradientForType, GradientType } from '@ui/theme/gradients'

export type GradientContainerProps = {
  gradient?: GradientType
  inModal?: boolean
}

const GradientContainer = ({ gradient = 'neutral', inModal = false }: GradientContainerProps) => (
  <Container $gradient={gradient} $inModal={inModal} />
)

const Container = styled.div<{ $gradient: GradientType; $inModal: boolean }>`
  width: 100%;
  height: 100%;
  position: ${({ $inModal }) => ($inModal ? 'absolute' : 'fixed')};
  z-index: ${({ $inModal }) => ($inModal ? 'inherit' : '-1')};
  top: 0;
  left: 0;
  background-image: ${({ $gradient }) => gradientForType($gradient)};
  background-repeat: no-repeat;
`

export default GradientContainer
